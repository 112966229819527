  .container {
    background-color: var(--tg-theme-secondary-bg-color); 
  }
  
  .skeletonList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 20px), 1fr)); 
    gap: 8px; 
    padding: 8px; 
    background-color: var(--tg-theme-secondary-bg-color); 
  }
    
  @media (max-width: 850px) {
    .skeletonList {
      grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 20px), 1fr)); 
    }
  }
  
  @media (max-width: 550px) {
      .skeletonList {
          grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
      }
  }
  
  @media (max-width: 300px) {
      .skeletonList {
          grid-template-columns: repeat(auto-fill, minmax(calc(100% - 20px), 1fr));
      }
  }
  
.skeletonItem {
    background-color: var(--tg-theme-section-bg-color);
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: flex-start;
    border-radius: 26px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    padding: 6px 6px 6px 6px;
    /* height: 276px; */
  }
    
.skeletonItem:hover {
    transform: translateY(-2px);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}
    
.skeletonImg {
  width: 100%;
  border-radius: 21px;
  overflow: hidden;
}
  
.productDetails {
  display: flex;
  flex-direction: column;
  height: 102px;
  padding: 10px 10px 0px;
}