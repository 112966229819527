/* OrderCard.module.css */
  
.link {
    text-decoration: none;
    color: inherit;
  }
  
.link:visited {
color: inherit;
}

.cardItems {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(25% - 20px), 1fr)); 
  gap: 20px; 
  padding: 20px; 
  background-color: var(--tg-theme-section-bg-color);; 
}

.orderItem {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: var(--tg-theme-section-bg-color);
  border-radius: 26px;
}

.orderItem:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.orderDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
}

@media (max-width: 850px) {
  .cardItems {
      grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 20px), 1fr)); 
  }
}

@media (max-width: 550px) {
  .cardItems {
      grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
  }
}

@media (max-width: 300px) {
  .cardItems {
      grid-template-columns: repeat(auto-fill, minmax(calc(100% - 20px), 1fr));
  }
}