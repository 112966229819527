
.orderListContainer {
    background-color: var(--tg-theme-secondary-bg-color);
  }
    
  .orderList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 8px;
    padding: 8px;
    background-color: var(--tg-theme-secondary-bg-color);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  