.paper {
  margin-top: 16px;
  padding: 16px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.attributesPair {
  padding: 10px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
