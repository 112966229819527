.paper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  
  .menu {
    background-color: var(--tg-theme-section-bg-color) !important;
    height: 80px !important;
    border-top: 0.1px solid var(--tg-theme-hint-color) !important;
  }
  
  .action {
    color: var(--tg-theme-button-color) !important;
  }