
.cardItem {
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--tg-theme-secondary-bg-color);
    border-radius: 10px; 
    padding: 10px;
  }
  
  .cardItem img {
    max-width: 85px;
    max-height: 85px;
    object-fit: contain;
  }
  