.skeletonContainer{
    background-color: var(--tg-theme-secondary-bg-color);
  }
  
  .skeletonList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 8px;
    padding: 8px;
    background-color: var(--tg-theme-secondary-bg-color);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
    
  .skeletonCard {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 20px;
    padding: 20px;
    background-color: var(--tg-theme-section-bg-color);
    border-radius: 26px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .cardItems {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 20px), 1fr)); 
    gap: 20px; 
    padding: 20px; 
  }
  
  .textItem {
    display: flex;
    justify-content: center;
  }
  
  @media (max-width: 850px) {
    .cardItems {
        grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 20px), 1fr)); 
    }
  }
  
  @media (max-width: 550px) {
    .cardItems {
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
    }
  }
  
  @media (max-width: 300px) {
    .cardItems {
        grid-template-columns: repeat(auto-fill, minmax(calc(100% - 20px), 1fr));
    }
  }
  