
.greetingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: var(--tg-theme-secondary-bg-color); */
    /* background-color: var(--tg-theme-bg-color); */
    background-color: #D6B3E6;
    text-align: center;
    height: 100vh;
  }
  
  .greetingImg {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .textContainer {
    font-size: 3rem;
    white-space: nowrap;
    color: #e53935;
  }
  
  .textContainer span {
    opacity: 0;
    animation: draw 1s forwards;
    animation-delay: calc(0.1s * var(--i));
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .hieroglyphWrapper {
    margin-top: 20px;
  }

  .hieroglyphSpace {
    height: 122px;
  }
  
  .fallingHieroglyph {
    max-width: 100px;
    width: 100%;
    transform: translateX(-50%) scale(1.5) perspective(500px);
    animation: fall 1s ease-in forwards;
  }

  @keyframes draw {
    to {
      opacity: 1;
    }
  }
  
  @keyframes fall {
    0% {
      transform: translateY(-50px) translateZ(0) scale(3) perspective(500px); /* Start above the viewport with perspective */
      opacity: 0;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      transform: translateY(0) translateZ(0) scale(0.5) perspective(500px); /* End at the bottom with perspective */
      opacity: 1;
    }
  }