
.orderDetailsContainer{
    background-color: var(--tg-theme-secondary-bg-color);
    padding-top: 56px;
  }
  
  .orderDetails {
    padding: 8px;
    background-color: var(--tg-theme-secondary-bg-color);
  }
  
  .icon {
    color: var(--tg-theme-hint-color);
  }
  
  .recieverSection {
    display: flex;
    margin: 8px 0px 1px;
    background-color: var(--tg-theme-section-bg-color);
    border-radius: 26px 26px 0px 0px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .shippingSection {
    display: flex;
    margin: 1px 0px 1px;
    background-color: var(--tg-theme-section-bg-color);
    /* border-radius: 0px 0px 26px 26px; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  
  .totalSection{
    display: flex;
    margin: 1px 0px;
    border-radius: 0px 0px 26px 26px;
    background-color: var(--tg-theme-section-bg-color); 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  
  .totalContainer{
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .shippingIconContainer {
    padding: 32px 16px 32px;
  }
  
  .shippingInfoContainer {
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .orderDetailsItems {
    display: grid;
    background-color: var(--tg-theme-section-bg-color); 
    padding: 8px;
    margin: 8px 0px;
    border-radius: 26px;
  }
  
  .headerText {
    justify-content: space-around;
  }
  
  .emptyDiv{
    margin: 20px;
  }

  .stepperContainer{
    padding: 16px 32px; 
    margin: 8px 0px;
  }

  .buttonContainer{
    padding: 8px;
  }

  .buttonsContainer{
    padding: 8px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .confirmationContainer{
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
  }