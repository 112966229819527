
.orderDetailsItem {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }

.orderDetailsItem img {
    max-width: 100px;
    height: auto;
    border-radius: 9px;
}

.selectedItemWrapper {
    filter: brightness(1.25);
    background-color: var(--tg-theme-section-bg-color);
    border-radius: 17px;
  }

.productDetails{
    padding: 8px;
    text-align: left;
    /* display:; */
}

.imageContainer{
    padding: 8px;
    display: flex;
}