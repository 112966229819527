.appFooter {
  background-color: var(--tg-theme-bg-color);
  min-height: 120px;
  padding: 14px 0 160px;
  color: var(--tg-theme-hint-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
}
