
.paper {
    margin-top: 16px;
    padding: 16px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

.details {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Display up to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: -webkit-line-clamp 0.3s ease-in-out;
  }

.expandedText {
    -webkit-line-clamp: unset;
  }

  