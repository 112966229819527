.productCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 26px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 6px 6px 6px 6px;
  cursor: pointer;
  background-color: var(--tg-theme-section-bg-color);
  transition: transform 0.3s, box-shadow 0.3s;
}

.productCard:hover {
  transform: translateY(-2px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.productCard img {
  height: auto;
  border-radius: 21px;
}

.imageContainer {
  width: 100%;
  border-radius: 21px; /* Apply border radius to match the image */
  overflow: hidden; /* Clip the image if it overflows the container */
  position: relative; /* Ensure relative positioning for absolute child */
}

.productDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 112px;
  margin: 10px 0 5px;
}

.productDetails h2 {
  font-size: 1.2rem;
  margin-bottom: 4px;
}

.productDetails p {
  font-size: 1rem;
  margin-bottom: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
  height: 3.9em;
}

.cartButton {
  width: 100%;
}

.cartButton button {
  background-color: var(--tg-theme-button-color);
  color: var(--tg-theme-button-text-color);
  border-radius: 21px;
  width: 100%;
  height: 42px;
}

.cartButton button:hover {
  background-color: var(--tg-theme-button-color);
}

.cartButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.description {
  text-align: left;
  padding: 10px 10px 0px;
  color: var(--tg-theme-text-color);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price {
  color: var(--tg-theme-destructive-text-color);
  text-align: left;
  padding: 0px 10px 10px;
}

.link {
  text-decoration: none;
  color: inherit;
}

.link:visited {
  color: inherit;
}
